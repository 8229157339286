import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';
import { AWS_URL } from '../../constants/app';

import { EAuthMethods } from '../authForm/authForm.types';

const getInitialState = () => ({
  isFetching: false,
  id: 1,
  name: '',
  settingsLoaded: false,
  logoUrl: '',
  brandShareUrl: '',
  skipProfile: false,
  forceEmployeeId: false,
  forceIsraeliId: false,
  defaultSignupEmail: false,
  allowedAuthMethods: [
    { type: EAuthMethods.EMAIL },
    { type: EAuthMethods.PHONE, isDefault: true },
    { type: EAuthMethods.GOOGLE },
    { type: EAuthMethods.MICROSOFT },
    { type: EAuthMethods.APPLE },
  ],
  branding: {
    logo_url: null,
    login_title: null,
    login_subtitle: null,
    signup_title: null,
    signup_subtitle: null,
  },
});

const organizationSlice = createSlice({
  name: 'organization',
  initialState: getInitialState(),
  reducers: {
    resetOrgSettings: (state) => {
      state = getInitialState();
      return state;
    },
    getOrgSettings: (state, action) => {
      state.id = action.payload;
      state.isFetching = true;
    },
    getOrgSettingsSuccess: (state, action) => {
      const settings = action.payload;
      let phoneMethod, defaultMethod;

      settings.allowed_auth_methods?.forEach((method) => {
        if (method.type === EAuthMethods.PHONE) {
          phoneMethod = method;
        }
        if (method.is_default) {
          defaultMethod = method;
        }
      });

      const allowedAuthMethods = settings.allowed_auth_methods?.map((_method) => {
        const method = { ..._method };

        if (method.is_default) {
          method.isDefault = true;
        }

        if (!defaultMethod && phoneMethod && method.type === EAuthMethods.PHONE) {
          method.isDefault = true;
        }

        if (!defaultMethod && !phoneMethod && method.type === EAuthMethods.EMAIL) {
          method.isDefault = true;
        }

        delete method.is_default;
        return method;
      });

      state.allowedAuthMethods = allowedAuthMethods || getInitialState().allowedAuthMethods;
      state.id = settings.org; // needed for E2E login command
      state.settingsLoaded = true;
      state.skipProfile = settings.skip_profile;
      state.forceEmployeeId = settings.force_employee_id;
      state.forceIsraeliId = settings.force_israeli_id;
      state.defaultSignupEmail = settings.default_signup_email;
      state.isFetching = false;
      state.branding = settings.branding || getInitialState().branding;
    },
    getOrgSettingsFail: (state) => {
      state.isFetching = false;
    },
    setBranding: (state, { payload }) => {
      state.logoUrl = payload.logo_url ? AWS_URL + '/' + payload.logo_url : '';
      state.brandShareUrl = payload.brand_share_url ? AWS_URL + '/' + payload.brand_share_url : '';
      state.name = payload.name;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateDefaultOrg: (state, action) => {
      //do nothing
    },
  },
});

export const {
  resetOrgSettings,
  getOrgSettings,
  getOrgSettingsSuccess,
  getOrgSettingsFail,
  updateDefaultOrg,
  setBranding,
} = organizationSlice.actions;

export default organizationSlice.reducer;

export const organizationSelector = (state: RootState) => state.organization;
