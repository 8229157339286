import React, { FC, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

import ReactPlayer, { HTML5Player } from '../../../components/shared/ReactPlayer';
import useVimeoLink from '../../../hooks/useVimeoLinks';
import { GtmVideo } from '../../../services/googleTagManager';

interface IProps {
  media_url: string | null;
  file_type: string;
  onCloseModal: () => void;
  playerRef: any;
}

const VideoPlayer: FC<IProps> = ({ onCloseModal, playerRef, file_type, media_url }) => {
  const isVimeo = file_type === 'video';
  const { fetchingLinkError, isFetchingLink, vimeoVideoUri, isVideoStillDecoding } = useVimeoLink(isVimeo, media_url);

  const currentTime = useRef(0);
  const isSeeking = useRef(false);

  const onProgress = ({ playedSeconds }) => {
    if (!isSeeking.current) {
      currentTime.current = playedSeconds;
    }
  };

  const debouncedSenVolumeData = useRef(debounce(() => sendVolumeChangeData(), 1000)).current;

  useEffect(() => {
    if (!playerRef.current || !isVimeo || fetchingLinkError || isVideoStillDecoding || isFetchingLink) {
      return;
    }

    const internalPlayer = playerRef.current.getInternalPlayer();
    const callback = () => {
      debouncedSenVolumeData();
    };
    internalPlayer?.addEventListener('volumechange', callback);

    return () => {
      internalPlayer?.removeEventListener('volumechange', callback);
    };
  }, [debouncedSenVolumeData, fetchingLinkError, isFetchingLink, isVideoStillDecoding, isVimeo, playerRef]);

  useEffect(() => {
    if (!playerRef.current || !isVimeo || fetchingLinkError || isVideoStillDecoding || isFetchingLink) {
      return;
    }

    const internalPlayer = playerRef.current.getInternalPlayer();
    const callback = () => {
      const tracks = internalPlayer?.textTracks;
      if (tracks.length > 0) {
        tracks[0].mode = 'showing'; // turn on subtitles
      }
    };

    internalPlayer?.addEventListener('loadedmetadata', callback);

    return () => {
      internalPlayer?.removeEventListener('loadedmetadata', callback);
    };
  }, [fetchingLinkError, isFetchingLink, isVideoStillDecoding, isVimeo, playerRef]);

  const handleVideoEnded = () => {
    const tagManagerVideo = new GtmVideo(
      'Video Ended',
      playerRef.current?.getCurrentTime(),
      playerRef.current?.getDuration(),
      vimeoVideoUri,
      isVimeo ? 'Vimeo' : 'Other',
    );
    tagManagerVideo.sendData();
    onCloseModal();
  };

  const debouncedSendSeekData = useRef(debounce((nextValue: number) => sendOnSeekData(nextValue), 1000)).current;

  const sendOnSeekData = (seconds: number) => {
    if (playerRef.current) {
      const tagManagerVideo = new GtmVideo(
        'Video Seek',
        seconds - 1,
        playerRef.current.getDuration(),
        vimeoVideoUri,
        isVimeo ? 'Vimeo' : 'Other',
        'gtm.seekFrom',
        currentTime.current,
      );

      tagManagerVideo.sendData();
    }
    isSeeking.current = false;
  };

  const sendVolumeChangeData = () => {
    if (!playerRef.current) {
      return;
    }

    const internalPlayer = playerRef.current.getInternalPlayer();
    const volume = internalPlayer.muted ? 'Muted' : `${(internalPlayer.volume * 100).toFixed(2)}%`;

    const tagManagerVideo = new GtmVideo(
      'Video Volume Changed',
      playerRef.current?.getCurrentTime(),
      playerRef.current?.getDuration(),
      vimeoVideoUri,
      isVimeo ? 'Vimeo' : 'Other',
      'gtm.videoVolume',
      volume,
    );

    tagManagerVideo.sendData();
  };

  const onSeek = (seconds: number) => {
    isSeeking.current = true;
    debouncedSendSeekData(seconds);
  };

  const onPlay = () => {
    const timer = setTimeout(() => {
      if (!isSeeking.current && playerRef.current) {
        const tagManagerVideo = new GtmVideo(
          'Video Played',
          playerRef.current.getCurrentTime() - 0.2,
          playerRef.current.getDuration(),
          vimeoVideoUri,
          isVimeo ? 'Vimeo' : 'Other',
        );

        tagManagerVideo.sendData();
      }
      clearTimeout(timer);
    }, 200);
  };

  const onPause = () => {
    const timer = setTimeout(() => {
      if (!isSeeking.current && playerRef.current) {
        const tagManagerVideo = new GtmVideo(
          'Video Paused',
          playerRef.current.getCurrentTime() - 0.2,
          playerRef.current.getDuration(),
          vimeoVideoUri,
          isVimeo ? 'Vimeo' : 'Other',
        );

        tagManagerVideo.sendData();
      }
      clearTimeout(timer);
    }, 200);
  };

  const videoUrl = isVimeo ? vimeoVideoUri : media_url;

  const Player = useMemo(() => {
    return isVimeo ? HTML5Player : ReactPlayer;
  }, [isVimeo]);

  const config = useMemo(() => {
    const isBitesStreaming =
      videoUrl &&
      ['video.small-bites.com', 'video.mybiteshares.com', 'video.mybites.io'].includes(new URL(videoUrl).hostname);

    return isVimeo
      ? {
          attributes: {
            controlsList: 'nodownload',
            crossOrigin: isBitesStreaming ? 'use-credentials' : 'anonymous',
            onContextMenu: (e) => e.preventDefault(),
          },
          tracks: [],
        }
      : {
          file: {
            attributes: {},
            tracks: [],
          },
        };
  }, [isVimeo, videoUrl]);

  const render = () => {
    if (isVimeo && isFetchingLink) {
      return null; // vimeo links are fetched
    } else if (isVimeo && isVideoStillDecoding) {
      return <S.Message>Video is still decoding</S.Message>;
    } else if (isVimeo && fetchingLinkError) {
      return <S.Message>Error occurred while playing the video </S.Message>;
    } else {
      return (
        <>
          <Player
            ref={playerRef}
            url={videoUrl}
            className='react-player'
            playing
            // loop
            width='100%'
            height='100%'
            controls={true}
            playsinline={true}
            onEnded={handleVideoEnded}
            onPlay={onPlay}
            onPause={onPause}
            onProgress={onProgress}
            onSeek={onSeek}
            config={config}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              // maxHeight: window.innerHeight - 100,
            }}
          />
        </>
      );
    }
  };

  return render();
};

const S = {
  Container: styled.div`
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    video {
      border-radius: 10px;
    }
  `,
  Message: styled.div`
    color: ${({ theme }) => theme.colors.white};
  `,
};

export default VideoPlayer;
