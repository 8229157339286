// eslint-disable-next-line import/named
import { createGlobalStyle, DefaultTheme } from 'styled-components';

const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>` 
 
html, body {
  height: 100%;
  overflow: hidden;
  -ms-overflow-style: none;
  touch-action: none;
}

  html {
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  }
  div, h1, h2, h3, h4, h5, h6, p {
    white-space: pre-wrap;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -ms-overflow-style: none;
  }

  *::-webkit-scrollbar {
    display: none;
  } 
  a, a:visited {
    text-decoration: none;
    color: rgb(87, 120, 206);
  }
  a:hover {
    text-decoration: underline;
  }

  button, input {
    border: none;
    outline: none;
    background-color: transparent;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  [class*=Snackbar_snackbar-wrapper-bottom-center] {
    margin: 0!important;
    bottom: 0!important;
  }

  [class*=Snackbar_snackbar__text] {
      padding-top: 8px!important;
      padding-bottom: 8px!important;
  }

  .grecaptcha-badge {
    display: none;
  }

  .ios-gte-17 ::cue {
    font-size: 0.7em;
  }

`;

export default GlobalStyle;
