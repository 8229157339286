import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currentFlowSelector } from '../../../store/authForm/authForm.selectors';
import { organizationBrandingSelector } from '../../../store/organization/organization.selectors';
import { EAuthFlowType } from '../auth.types';

const useAuthTitles = () => {
  const { t } = useTranslation();

  const organizationBranding = useSelector(organizationBrandingSelector);
  const currentFlow = useSelector(currentFlowSelector);

  const title = (() => {
    if (currentFlow === EAuthFlowType.SIGN_IN) {
      return organizationBranding.login_title || t('authForm.signInForm.title');
    }
    return organizationBranding.signup_title || t('authForm.signUpForm.title');
  })();

  const subtitle = (() => {
    if (currentFlow === EAuthFlowType.SIGN_IN) {
      return organizationBranding.login_subtitle || t('authForm.signInForm.subtitle');
    }
    return organizationBranding.signup_subtitle || t('authForm.signUpForm.subtitle');
  })();

  return { title, subtitle };
};
export default useAuthTitles;
