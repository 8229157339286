import { RootState } from '../index';

export const currentFlowSelector = (state: RootState) => state.authForm.currentFlow;
export const currentFormSelector = (state: RootState) => state.authForm.currentForm;
export const emailFormSelector = (state: RootState) => state.authForm.email;
export const phoneFormSelector = (state: RootState) => state.authForm.phone;
export const passwordFormSelector = (state: RootState) => state.authForm.password;
export const isAuthDataMissingSelector = (state: RootState) => state.authForm.isAuthDataMissing;
export const firstNameSelector = (state: RootState) => state.authForm.firstName;
export const lastNameSelector = (state: RootState) => state.authForm.lastName;
export const recoveryTokenSelector = (state: RootState) => state.authForm.recoveryToken;
export const userSelectedCountryCodeSelector = (state: RootState) => state.authForm.userSelectedCountryCode;
