import AuthActionTypes, { IFinalizeProfileAction, IUserOrganizations } from './auth.types';

import {
  IGetBitesTokenFromSocialBody,
  ISigninCredentials,
  ISignupEmailCredentials,
  INewPasswordCredentials,
  IAuth,
  TSignupErrorCodes,
  ILoginWithPhone,
  ISignupPhone,
  IPasswordRecoveryBody,
} from '../../types/auth';
import microsoftTeams from '../../services/msTeams';

export const setIsInitialAuthInProgress = (payload: boolean) => ({
  type: AuthActionTypes.SET_IS_INITIAL_AUTH_IN_PROGRESS,
  payload,
});

export const login = (credentials: ISigninCredentials) => ({
  type: AuthActionTypes.LOGIN_REQUEST,
  payload: credentials,
});

export const loginWithPhone = (payload: ILoginWithPhone) => ({
  type: AuthActionTypes.LOGIN_WITH_PHONE_REQUEST,
  payload,
});

export const loginSuccess = () => ({
  type: AuthActionTypes.LOGIN_SUCCESS,
});

export const loginError = () => ({
  type: AuthActionTypes.LOGIN_ERROR,
});

export const setIsSocialLoading = (isLoading: boolean) => ({
  type: AuthActionTypes.SET_IS_SOCIAL_LOGIN,
  payload: isLoading,
});

export const socialLoginRequest = (tokenPayload: IGetBitesTokenFromSocialBody & { email?: string }) => ({
  type: AuthActionTypes.SOCIAL_LOGIN_REQUEST,
  payload: tokenPayload,
});

export const socialLoginEnd = (payload: { error: boolean }) => ({
  type: AuthActionTypes.SOCIAL_LOGIN_REQUEST_END,
  payload,
});

export const signupWithEmail = (credentials: ISignupEmailCredentials) => ({
  type: AuthActionTypes.SIGNUP_WITH_EMAIL_REQUEST,
  payload: credentials,
});

export const signupWithPhone = (payload: ISignupPhone) => ({
  type: AuthActionTypes.SIGNUP_WITH_PHONE_REQUEST,
  payload,
});

export const setWrongVerificationCode = (phoneNumber: any) => ({
  type: AuthActionTypes.SET_WRONG_VERIFICATION_CODE,
  payload: phoneNumber,
});

export const clearUserProfileErrorsByType = (payload: string[]) => ({
  type: AuthActionTypes.CLEAR_USER_PROFILE_ERRORS_BY_TYPE,
  payload,
});

export const finalizeUserProfile = (payload: IFinalizeProfileAction) => ({
  type: AuthActionTypes.FINALIZE_USER_PROFILE,
  payload,
});

export const setIsPhoneMissing = (isPhoneMissing: boolean) => ({
  type: AuthActionTypes.SET_IS_PHONE_MISSING,
  payload: isPhoneMissing,
});

export const signupSuccess = () => ({
  type: AuthActionTypes.SIGNUP_SUCCESS,
});

export const setAuth = (payload: { user: IAuth }) => ({
  type: AuthActionTypes.SET_AUTH,
  payload,
});

export const refreshUser = (payload?: { processId: string }) => ({
  type: AuthActionTypes.REFRESH_USER,
  payload,
});

export const refreshUserSuccess = (payload: { user: IUserOrganizations; isSignup: boolean }) => ({
  type: AuthActionTypes.REFRESH_USER_SUCCESS,
  payload,
});

export const refreshUserFailure = () => ({
  type: AuthActionTypes.REFRESH_USER_FAILURE,
});

export const setRefreshUserErrors = (payload: string[]) => ({
  type: AuthActionTypes.SET_REFRESH_USER_ERRORS,
  payload,
});

export const logout = (payload?: { noRedirection?: boolean; redirectUrl?: string; saveRedirectUri?: boolean }) => ({
  type: AuthActionTypes.LOGOUT,
  payload,
});

export const setMicrosoftContext = (microsoftContext: microsoftTeams.Context) => ({
  type: AuthActionTypes.SET_MICROSOFT_CONTEXT,
  payload: microsoftContext,
});

export const setTokenAndGetUser = (payload: { token?: string; processId: string }) => ({
  type: AuthActionTypes.SET_TOKEN_AND_GET_USER,
  payload,
});

export const passwordRecovery = (payload: IPasswordRecoveryBody) => ({
  type: AuthActionTypes.PASSWORD_RECOVERY_REQUEST,
  payload,
});

export const passwordRecoverySuccess = () => ({
  type: AuthActionTypes.PASSWORD_RECOVERY_SUCCESS,
});

export const passwordRecoveryError = () => ({
  type: AuthActionTypes.PASSWORD_RECOVERY_ERROR,
  payload: true,
});

export const setNewRecoverPassword = (payload: INewPasswordCredentials) => ({
  type: AuthActionTypes.SET_NEW_RECOVERY_PASSWORD_REQUEST,
  payload,
});

export const setNewRecoverPasswordError = (errors: string[]) => ({
  type: AuthActionTypes.SET_NEW_RECOVERY_PASSWORD_ERROR,
  payload: errors,
});

export const setIsRestrictedContentForAnotherOrgModalShown = (flag: boolean) => ({
  type: AuthActionTypes.SET_IS_RESTRICTED_CONTENT_FOR_ANOTHER_ORG_MODAL_SHOWN,
  payload: flag,
});

export const setIsRestrictedContentModalShown = (flag: boolean) => ({
  type: AuthActionTypes.SET_IS_RESTRICTED_CONTENT_MODAL_SHOWN,
  payload: flag,
});

export const setIsNothingToDisplayModalShown = (flag: boolean) => ({
  type: AuthActionTypes.SET_IS_NOTHING_TO_DISPLAY_MODAL_SHOWN,
  payload: flag,
});

export const setIsRestrictedAllowJoinModalShown = (flag: boolean) => ({
  type: AuthActionTypes.SET_IS_RETRICTED_ALLOW_JOIN_MODAL_SHOWN,
  payload: flag,
});

export const setIsNotLoading = () => ({
  type: AuthActionTypes.SET_IS_NOT_LOADING,
});

export const setAuthErrorCodes = (errorCodes: TSignupErrorCodes[]) => ({
  type: AuthActionTypes.SET_AUTH_ERROR_CODES,
  payload: errorCodes,
});

export const resetAuthError = () => ({
  type: AuthActionTypes.RESET_AUTH_ERROR,
});

export const resetAuthErrors = () => ({
  type: AuthActionTypes.RESET_AUTH_ERRORS,
});
