import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import DeleteCommentModal from './DeleteCommentModal';

import { ReactComponent as TrashIcon } from '../../../../assets/icons/delete-comment.svg';
import DeleteIcon from '../../../../assets/icons/delete_icon.svg';
import useCustomTranslation from '../../../../hooks/useCustomTranslation';
import { deleteCommentRequest } from '../../../../store/bite/bite.actions';

interface IProps {
  commentId: string;
  type: string;
}

const DeleteComment: FC<IProps> = ({ commentId, type }) => {
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { prefixedT } = useCustomTranslation('screens.viewBite.DeleteModal');

  const deleteComment = useCallback(() => {
    dispatch(deleteCommentRequest({ type: type, id: commentId }));
  }, [commentId, dispatch, type]);

  const handleCancel = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleDelete = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  return (
    <>
      <S.DeleteComment onClick={handleDelete} id='commentButton'>
        <TrashIcon id='deleteButtonIcon' />
      </S.DeleteComment>
      <DeleteCommentModal
        isOpen={isDialogOpen}
        modalIcon={DeleteIcon}
        onConfirm={deleteComment}
        onCancel={handleCancel}
        messageText={prefixedT('title')}
        confirmText={prefixedT('deleteBtn')}
        cancelText={prefixedT('discard')}
      />
    </>
  );
};
const S: any = {};

S.DeleteComment = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default DeleteComment;
