export enum EAuthFormType {
  AUTH = 'AUTH',
  FORGOT_PASSWORD_EMAIL = 'FORGOT_PASSWORD_EMAIL',
  FORGOT_PASSWORD_INFO = 'FORGOT_PASSWORD_INFO',
  FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  PHONE_NUMBER = 'PHONE_NUMBER',
  EMAIL = 'EMAIL',
  ENTER_CODE_EMAIL = 'ENTER_CODE_EMAIL',
  ENTER_CODE_PHONE = 'ENTER_CODE_PHONE',
  USER_INFO = 'USER_INFO',
  EXTENDED_INFO = 'EXTENDED_INFO',
}

export enum EAuthFlowType {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
}
