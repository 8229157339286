import { isProd } from '../utils/env';

export const APP = 'frontliner';
export const MS_PERSONAL_TENANT_ID = '9188040d-6c67-4c5b-b112-36a304b66dad';
export const URL_ACCESS_TOKEN_PLACEHOLDER = 'ACCESS_TOKEN';
export const URL_ERROR_CODE_PLACEHOLDER = 'ERROR_CODE';
export const URL_ACTION_SOCIAL_AUTH = 'social-auth';
export const IPBASE_TOKEN = '6jpbXFjnWIqxDA9QrFHqPkPpf1MkQFPs5f2w8Sz7';

export const AWS_URL = isProd() ? 'https://dfypq0ao8swn5.cloudfront.net' : 'https://bites2-be-staging.s3.amazonaws.com';

export const MAX_RETRIES_DEFAULT = 5;

export const version = '1.0.30';
// eslint-disable-next-line no-console
console.log('Frontliner version: ', version);
