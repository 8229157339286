import { IComment } from '../../../../types/bite';

export enum ERelativeTime {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Last3Days = 'Last3Days',
  Last7Days = 'Last7Days',
  LastWeek = 'LastWeek',
  Older = 'Older',
}

export enum EFlattenedCommentType {
  Comment = 'Comment',
  Header = 'Header',
}

type TFlattenedComment =
  | { content: IComment; type: EFlattenedCommentType.Comment }
  | { content: string; type: EFlattenedCommentType.Header };

export type TFlattenedComments = TFlattenedComment[];

export type TCommentItemRefType = HTMLDivElement | HTMLHeadingElement;
