import { put, take } from '@redux-saga/core/effects';

import history from '../navigation/history';
import { getOrgSettings } from '../store/organization/organization.slice';
import { setTitle } from '../store/socialShare/socialShare.slice';
import * as authActions from '../store/auth/auth.actions';
import { setRedirectUri } from './redirection';
import { log } from '../store/tracking/tracking.slice';
import { cloneDeep } from 'lodash';
import { select } from 'redux-saga/effects';
import { authSelector, isInitialAuthInProgressSelector } from '../store/auth/auth.selectors';
import AuthActionTypes from '../store/auth/auth.types';
import { setQueryParam } from './queryParams';

// this must be called in a context of a saga otherwise it will crash
export default function* handleUnaothrizedContentResponse({ error, processId }) {
  const isInitialAuthInProgress = yield select(isInitialAuthInProgressSelector);
  if (isInitialAuthInProgress) {
    yield take(AuthActionTypes.SET_IS_INITIAL_AUTH_IN_PROGRESS);
  }

  const user = yield select(authSelector);
  const isUser = !!user;

  yield put(
    log({
      event: 'handleUnaothrizedContentResponse',
      processId,
      data: {
        isUser,
        error,
        // axios case
        errorResponse: cloneDeep(error?.response),
      },
    }),
  );

  setRedirectUri({ redirectUri: window.location.pathname + window.location.search });

  const sharingMode = error.response.data.detail?.sharing_mode;
  const orgId = error.response.data.detail?.orgid;

  // if content requires login and user is either a guest or doesn't belong to the organization
  if (error.response.status === 403) {
    if (isUser) {
      if (['require_login', 'no_one'].includes(sharingMode)) {
        yield put(authActions.setIsRestrictedContentModalShown(true));
      } else if (sharingMode === 'team_only') {
        yield put(authActions.setIsRestrictedContentForAnotherOrgModalShown(true));
      } else if (sharingMode === 'team_only_allow_join') {
        yield put(authActions.setIsRestrictedAllowJoinModalShown(true));
      }
    } else {
      const { orgid, name } = error.response.data.detail;
      yield put(setTitle(name));
      yield put(getOrgSettings(orgid));
      if (sharingMode === 'no_one') {
        yield put(authActions.setIsRestrictedContentModalShown(true));
      }

      // redirection to auth screen
      if (sharingMode === 'require_login' || sharingMode === 'team_only' || sharingMode === 'team_only_allow_join') {
        if (orgId) {
          setQueryParam({ name: 'orgId', value: orgId, replace: true });
        }
      }
    }
    // if sharing mode is anyone means that there is no content to display
    if (sharingMode === 'anyone') {
      yield put(authActions.setIsNothingToDisplayModalShown(true));
    }
  }

  history.push({ ...history.location, pathname: '/' });
}
