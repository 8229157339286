import React, { CSSProperties, FC, memo, ReactNode, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import i18n from '../../../locale/i18n';

interface IProps {
  title: string;
  onClick: () => void;
  icon: ReactNode;
  style?: CSSProperties;
  dataSet?: { [key: string]: string };
}

const SocialButton: FC<IProps> = ({ title, onClick, icon, style, dataSet }) => {
  const isRtl = i18n.dir(i18n?.language) === 'rtl';
  const [isPressed, setIsPressed] = useState(false);

  const handleMouseDown = useCallback(() => {
    setIsPressed(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsPressed(false);
  }, []);

  return (
    <S.Button
      isPressed={isPressed}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onClick={onClick}
      style={style}
      data-cy={dataSet}
    >
      {icon && <S.Icon isRtl={isRtl}>{icon}</S.Icon>}
      <S.Title>{title}</S.Title>
    </S.Button>
  );
};

const S = {
  Button: styled.div<{ isPressed: boolean }>`
    cursor: pointer;
    display: flex;
    flex: 1;
    width: 100%;
    height: 56px;
    position: relative;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.white};
    padding-left: 30px;
    opacity: ${({ isPressed }) => (isPressed ? 0.3 : 1)};
    border-radius: 10px;
    box-shadow: 0 7px 24px 0 rgba(106, 105, 125, 0.3);
    margin-bottom: 12px;
  `,
  Icon: styled.div<{ isRtl?: boolean }>`
    position: absolute;
    ${({ isRtl }) =>
      isRtl
        ? css`
            right: 16px;
          `
        : css`
            left: 16px;
          `}
  `,
  Title: styled.span`
    font-size: 14px;
    line-height: 16px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.text};
  `,
};

export default memo(SocialButton);
