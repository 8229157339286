import { useCallback, useEffect, useMemo, useRef } from 'react';

import { IComment } from '../../../../types/bite';
import useCustomTranslation from '../../../../hooks/useCustomTranslation';
import { EFlattenedCommentType, ERelativeTime, TFlattenedComments } from '../types';
import { useSelector } from 'react-redux';
import { latestChangedCommentIndexSelector } from '../../../../store/bite/bite.selectors';

const DEFAULT_COMMENT_HEIGHT = 113 + 15; // 113px for the comment, 15px for the margin-bottom
const ONE_DAY_MS = 86400000;
const TWO_DAYS_MS = ONE_DAY_MS * 2;
const THREE_DAYS_MS = ONE_DAY_MS * 3;
const SEVEN_DAYS_MS = ONE_DAY_MS * 7;

const useCommentList = (comments: IComment[]) => {
  const { prefixedT } = useCustomTranslation('screens.viewBite.Discussion');
  const listRef = useRef(null);
  const sizeMap = useRef(new Map<number, number>());
  const isFirstRenderRef = useRef(true);
  const latestChangedCommentIndex = useSelector(latestChangedCommentIndexSelector);

  const getSize = useCallback((index: number) => sizeMap.current.get(index) || DEFAULT_COMMENT_HEIGHT, []);

  const setSize = useCallback(
    (index, size) => {
      const isSizeChanged = sizeMap.current.get(index) !== size;

      if (!isSizeChanged) {
        return;
      }

      sizeMap.current.set(index, size);
      if (listRef.current) {
        listRef.current.resetAfterIndex(index);
      }
    },
    [listRef],
  );

  useEffect(() => {
    if (listRef.current) {
      // Reset all rows since we don't have access to the index of the item that needs to change when using redux lifecycle
      listRef.current.resetAfterIndex(0);
    }
  }, [comments, listRef]);

  const getRelativeTimeByDiff = useCallback(
    (timeDiff) => {
      if (timeDiff < ONE_DAY_MS) {
        return prefixedT(ERelativeTime.Today);
      }
      if (timeDiff < TWO_DAYS_MS) {
        return prefixedT(ERelativeTime.Yesterday);
      }
      if (timeDiff < THREE_DAYS_MS) {
        return prefixedT(ERelativeTime.Last3Days);
      }
      if (timeDiff < SEVEN_DAYS_MS) {
        return prefixedT(ERelativeTime.Last7Days);
      }
      return prefixedT(ERelativeTime.Older);
    },
    [prefixedT],
  );

  const flattenedComments = useMemo(() => {
    const result: TFlattenedComments = [];
    const currentTimestamp = Date.now();
    const timeAggregation = new Set();

    comments.forEach((currComment) => {
      const timeDiff = currentTimestamp - new Date(currComment.created_at).getTime();
      const timeAgo = getRelativeTimeByDiff(timeDiff);

      if (!timeAggregation.has(timeAgo)) {
        timeAggregation.add(timeAgo);
        result.push({ type: EFlattenedCommentType.Header, content: timeAgo });
      }

      result.push({ type: EFlattenedCommentType.Comment, content: currComment });
    });

    return result;
  }, [comments, getRelativeTimeByDiff]);

  useEffect(() => {
    if (!isFirstRenderRef.current && listRef.current) {
      const scrollToIndex = latestChangedCommentIndex === null ? flattenedComments.length : latestChangedCommentIndex;

      listRef.current.scrollToItem(scrollToIndex + 1, 'center');
    }

    if (isFirstRenderRef) {
      isFirstRenderRef.current = false;
    }
  }, [flattenedComments, comments, latestChangedCommentIndex]);

  return { flattenedComments, listRef, setSize, getSize };
};

export default useCommentList;
