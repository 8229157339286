import React, { memo, useEffect, useMemo } from 'react';
import SubtitlesTranslationsControls from './SubtitlesTranslationsControls';
import SubtitlesAutoTranslationsControls from './SubtitlesAutoTranslationsControls';
import useAlwaysTranslate from '../../../../hooks/useAlwaysTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { defineSubtitlesLocale } from '../../../../store/bite/bite.actions';
import localStorage from '../../../../utils/localStorage';
import useBrowserLang from '../../../../hooks/useBrowserLang';
import { subtitlesDisabledSelector } from '../../../../store/bite/bite.selectors';

interface IProps {
  mode?: 'CONTROLS';
  withLocalesTabs?: boolean;
  withAlwaysTranslate?: boolean;
  withDisplayAnimation: boolean;
  currentLocale: string;
  subtitles: { locale: string; enabled: boolean }[];
  isVisible: boolean;
  isLoading: boolean;
  isError: boolean;
  translatingIntoLocale: string | null;
  onSelect: (locale: string) => void;
}

const SubtitlesTranslations = ({
  mode,
  withLocalesTabs,
  withAlwaysTranslate,
  withDisplayAnimation,
  currentLocale,
  subtitles,
  isVisible,
  isLoading,
  isError,
  translatingIntoLocale,
  onSelect,
}: IProps) => {
  const dispatch = useDispatch();

  const subtitlesDisabled = useSelector(subtitlesDisabledSelector);

  const enabledSubtitles = useMemo(() => subtitles?.find((x) => x.enabled), [subtitles]);

  const { initialIsAlwaysTranslate, initialLocale } = useAlwaysTranslate();
  const browserLang = useBrowserLang();

  const closedTranslationControls = useMemo(() => {
    const closedTranslationControlsTsRaw = localStorage.getItem('closedTranslationControlsTs');

    if (!closedTranslationControlsTsRaw) {
      return false;
    }

    const closedTranslationControlsTs = parseInt(closedTranslationControlsTsRaw, 10);

    if (isNaN(closedTranslationControlsTs)) {
      return false;
    }

    return closedTranslationControlsTs + 7 * 24 * 60 * 60 * 1000 > Date.now();
  }, []);

  useEffect(() => {
    if (enabledSubtitles?.locale !== null || initialIsAlwaysTranslate) {
      return;
    }

    dispatch(defineSubtitlesLocale());
  }, [dispatch, enabledSubtitles?.locale, initialIsAlwaysTranslate]);

  if (
    !enabledSubtitles ||
    subtitlesDisabled ||
    (!initialIsAlwaysTranslate && !enabledSubtitles.locale) ||
    (!mode &&
      ((initialIsAlwaysTranslate && enabledSubtitles.locale === initialLocale) ||
        (!initialIsAlwaysTranslate && enabledSubtitles.locale === browserLang) ||
        closedTranslationControls))
  ) {
    return null;
  }

  if (!mode && initialIsAlwaysTranslate) {
    return (
      <SubtitlesAutoTranslationsControls
        withDisplayAnimation={withDisplayAnimation}
        currentLocale={currentLocale}
        enabledLocale={enabledSubtitles.locale}
        isVisible={isVisible}
        isLoading={isLoading}
        isError={isError}
        translatingIntoLocale={translatingIntoLocale}
        onSelect={onSelect}
      />
    );
  }

  return (
    <SubtitlesTranslationsControls
      withLocalesTabs={withLocalesTabs}
      withAlwaysTranslate={withAlwaysTranslate}
      withDisplayAnimation={withDisplayAnimation}
      currentLocale={currentLocale}
      enabledLocale={enabledSubtitles.locale}
      isVisible={isVisible}
      isLoading={isLoading}
      isError={isError}
      translatingIntoLocale={translatingIntoLocale}
      onSelect={onSelect}
    />
  );
};
export default memo(SubtitlesTranslations);
