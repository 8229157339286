import React, { FC, memo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import EnterEmail from './common/ForgotPassword/EnterEmail';
import ResetInfo from './common/ForgotPassword/ResetInfo';
import ResetPassword from './common/ForgotPassword/ResetPassword';
import FormContainer from './common/FormContainer';
import { logout } from '../../store/auth/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import { currentFlowSelector, currentFormSelector } from '../../store/authForm/authForm.selectors';
import { EAuthFormType } from './auth.types';
import UserInfoForm from './common/UserInfoForm';
import { setCurrentForm } from '../../store/authForm/authForm.slice';
import { ssoInProgressSelector } from '../../store/auth/auth.selectors';
import SsoAuthLoader from './common/SsoAuthLoader';
import AuthForm from './common/AuthForm';
import PhoneNumberAuth from './common/PhoneNumber/PhoneNumberAuth';
import { useHistory } from 'react-router-dom';
import BackButton from '../../components/shared/Buttons/BackButton';
import EnterPhoneCode from './common/EnterCodeForm/EnterPhoneCode';
import EnterEmailCode from './common/EnterCodeForm/EnterEmailCode';
import OrgLogo from './common/OrgLogo';
import { log } from '../../store/tracking/tracking.slice';
import { allowedAuthMethodsSelector, orgSelector } from '../../store/organization/organization.selectors';
import SuccessInfo from './common/ForgotPassword/SuccessInfo';
import EmailForm from './common/EmailForm';
import { EAuthMethods } from '../../store/authForm/authForm.types';
import ExtendedInfo from './common/ExtendedInfo';

const Auth: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isFetching: isAllowedAuthMethodsLoading } = useSelector(orgSelector);
  const allowedAuthMethods = useSelector(allowedAuthMethodsSelector);
  const currentFlow = useSelector(currentFlowSelector);
  const currentForm = useSelector(currentFormSelector);
  const ssoInProgress = useSelector(ssoInProgressSelector);

  const defaultMethod = allowedAuthMethods.find((method) => method.isDefault);

  const isLoading = ssoInProgress || isAllowedAuthMethodsLoading || !currentFlow;

  const navigateTo = useCallback(
    (destination: EAuthFormType) => {
      dispatch(log({ event: 'Auth.navigateTo', data: { destination, currentForm } }));
      dispatch(setCurrentForm(destination));
    },
    [dispatch, currentForm],
  );

  const handleBackButtonClick = useCallback(() => {
    dispatch(
      log({
        event: 'Auth.handleBackButtonClick',
        data: { currentForm },
      }),
    );

    switch (currentForm) {
      case EAuthFormType.FORGOT_PASSWORD_RESET:
        history.replace({ ...history.location, pathname: '/auth' });
        break;
      case EAuthFormType.FORGOT_PASSWORD_INFO:
      case EAuthFormType.FORGOT_PASSWORD_EMAIL:
      case EAuthFormType.PHONE_NUMBER:
      case EAuthFormType.EMAIL:
        navigateTo(EAuthFormType.AUTH);
        break;
      case EAuthFormType.USER_INFO:
      case EAuthFormType.EXTENDED_INFO:
        dispatch(logout());
        navigateTo(EAuthFormType.AUTH);
        break;
      case EAuthFormType.ENTER_CODE_PHONE:
        if (defaultMethod && defaultMethod.type === EAuthMethods.EMAIL) {
          navigateTo(EAuthFormType.PHONE_NUMBER);
          break;
        }
        navigateTo(EAuthFormType.AUTH);
        break;
      case EAuthFormType.ENTER_CODE_EMAIL:
        if (defaultMethod && defaultMethod.type === EAuthMethods.PHONE) {
          navigateTo(EAuthFormType.EMAIL);
          break;
        }
        navigateTo(EAuthFormType.AUTH);
        break;
      default:
        history.replace({ ...history.location, pathname: '/auth' });
    }
  }, [dispatch, currentForm, navigateTo, defaultMethod, history]);

  const renderContent = () => {
    switch (currentForm) {
      case EAuthFormType.FORGOT_PASSWORD_EMAIL:
        return <EnterEmail />;
      case EAuthFormType.FORGOT_PASSWORD_INFO:
        return <ResetInfo />;
      case EAuthFormType.FORGOT_PASSWORD_SUCCESS:
        return <SuccessInfo />;
      case EAuthFormType.PHONE_NUMBER:
        return <PhoneNumberAuth />;
      case EAuthFormType.EMAIL:
        return <EmailForm />;
      case EAuthFormType.FORGOT_PASSWORD_RESET:
        return <ResetPassword />;
      case EAuthFormType.ENTER_CODE_PHONE:
        return <EnterPhoneCode />;
      case EAuthFormType.ENTER_CODE_EMAIL:
        return <EnterEmailCode />;
      case EAuthFormType.USER_INFO:
        return <UserInfoForm />;
      case EAuthFormType.EXTENDED_INFO:
        return <ExtendedInfo />;
      default:
        return <AuthForm />;
    }
  };

  useEffect(() => {
    dispatch(
      log({
        event: 'Auth: screen',
        data: {
          currentForm,
          currentFlow,
        },
      }),
    );
  }, [currentFlow, currentForm, dispatch]);

  return (
    <FormContainer>
      <S.BackButton onClick={handleBackButtonClick} />
      <S.Header>
        <OrgLogo />
      </S.Header>
      {renderContent()}
      {isLoading && <S.Loader />}
    </FormContainer>
  );
};

const S = {
  Loader: styled(SsoAuthLoader)`
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  Wrapper: styled.div`
    align-self: center;
    width: 398px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
    z-index: 1;
  `,
  BackButton: styled(BackButton)`
    height: 40px;
    width: 40px;
    margin: 11px 0 0 14px;
  `,
  EmptyView: styled.div`
    height: 40px;
    width: 40px;
    margin: 11px 0 0 14px;
  `,
  Header: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    pointer-events: none;
  `,
};

export default memo(Auth);
