import React, { FC, memo, useCallback } from 'react';
import styled from 'styled-components';
import BackgroundImage from '../../assets/images/auth-app-native-image.png';
import { useDispatch } from 'react-redux';
import { setCurrentFlow, setCurrentForm } from '../../store/authForm/authForm.slice';
import { EAuthFlowType, EAuthFormType } from './auth.types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../components/shared/Buttons';
import OrgLogo from './common/OrgLogo';
import { ReactComponent as SmallBitesLogo } from '../../assets/icons/small-logo.svg';
import { isMobile } from '../../utils/utils';
import { signInDataCy } from './initialScreen.constants';

const AuthInitial: FC = () => {
  const isDevice = isMobile();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSignInButtonPress = useCallback(() => {
    dispatch(setCurrentForm(EAuthFormType.AUTH));
    dispatch(setCurrentFlow(EAuthFlowType.SIGN_IN));
    history.push({ ...history.location, pathname: '/auth/signin' });
  }, [dispatch, history]);

  const handleSignUpButtonPress = useCallback(() => {
    dispatch(setCurrentForm(EAuthFormType.AUTH));
    dispatch(setCurrentFlow(EAuthFlowType.SIGN_UP));
    history.push({ ...history.location, pathname: '/auth/signup' });
  }, [dispatch, history]);

  return (
    <S.Container>
      <S.Header isDevice={isDevice}>
        <OrgLogo />
      </S.Header>
      <S.BackgroundImage src={BackgroundImage} />
      <S.LinearGradient />
      <S.Content>
        <S.Text>{t('initialScreen.title')}</S.Text>
        <S.SignInButton onClick={handleSignInButtonPress} width={220} data-cy={signInDataCy}>
          {t('initialScreen.signIn')}
        </S.SignInButton>
        <S.SignUpButton onClick={handleSignUpButtonPress} width={220}>
          {t('initialScreen.signUp')}
        </S.SignUpButton>
      </S.Content>
      <S.PoweredBy>
        <SmallBitesLogo style={S.BitesLogoStyles} />
        {t('common.poweredByBites')}
      </S.PoweredBy>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  `,
  Header: styled.div<{ isDevice?: boolean }>`
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: ${({ isDevice }) => (!isDevice ? '94%' : '100%')};
    height: 60px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ isDevice }) => (!isDevice ? '0 0 45px 45px' : '0 0 10px 10px')};
    z-index: 2;
  `,
  Content: styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    bottom: 0;
  `,
  BackgroundImage: styled.img`
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-position: 0 0;
    object-fit: cover;
  `,
  Text: styled.span`
    display: flex;
    font-size: 44px;
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    margin-bottom: 20px;
    font-weight: 400;
    line-height: 45px;
    width: 295px;
    margin-left: 10px;
    z-index: 1;
  `,
  SignInButton: styled(Button)`
    display: flex;
    width: 298px;
    height: 48px;
    margin-bottom: 15px;
  `,
  SignUpButton: styled(Button)`
    display: flex;
    height: 48px;
    margin-bottom: 60px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  LinearGradient: styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0.8) 100%),
      linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0.8) 100%);
    opacity: 0.8;
  `,
  PoweredBy: styled.span`
    position: absolute;
    bottom: 0;
    display: flex;
    align-self: center;
    margin-bottom: 20px;
    font-weight: 400;
    line-height: 15px;
    margin-left: 10px;
    z-index: 1;
    font-style: normal;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.gray19};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
  BitesLogoStyles: {
    marginRight: 5,
  },
};

export default memo(AuthInitial);
