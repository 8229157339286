import React, { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '../../shared/Buttons';
import { ReactComponent as BitesIcon } from '../../../assets/icons/logo-without-text.svg';
import { useSelector } from 'react-redux';
import { geofenceContentInfoSelector, isContentNotAvailableSelector } from '../../../store/geofence/geofence.selectors';
import { sessionId } from '../../../store/tracking/tracking.constants';
import { EContentType } from '../../../store/geofence/geofence.types';

interface IProps {
  onApply: () => void;
}

const CONTENT_PATH = {
  [EContentType.playlist]: 'playlists',
  [EContentType.quiz]: 'quiz',
  [EContentType.bite]: 'bites',
};

const InitialGeofenceModal: React.FC<IProps> = ({ onApply }) => {
  const { t } = useTranslation();

  const isContentNotAvailable = useSelector(isContentNotAvailableSelector);
  const contentInfo = useSelector(geofenceContentInfoSelector);

  const contactUsMessage = useMemo(() => {
    const link = `${window.location.origin}/${CONTENT_PATH[contentInfo?.type]}/${contentInfo?.id}`;
    return `I need help accessing content from my location\nThis is the content url: ${link}\nThis is my session id: ${sessionId}`;
  }, [contentInfo]);

  const handleContactUs = useCallback(() => {
    window.Intercom('boot');
    window.Intercom('showNewMessage', contactUsMessage);
  }, [contactUsMessage]);

  return (
    <S.Container>
      <S.BitesIcon>
        <BitesIcon />
      </S.BitesIcon>
      <S.Content>
        <S.Header>
          {t('geofence.initialModal.welcome')}
          {'\n'}
        </S.Header>
        <S.ReadyText>
          {t('geofence.initialModal.ready')}
          {'\n'}
        </S.ReadyText>
        <S.Text>
          {t('geofence.initialModal.connect')}
          <S.BoldText> {t('geofence.initialModal.wifi')}</S.BoldText> {t('geofence.initialModal.or')}
          <S.BoldText> {t('geofence.initialModal.locationAccess')}</S.BoldText>{' '}
          {t('geofence.initialModal.letTheMagicBegin')}
        </S.Text>
        {isContentNotAvailable && (
          <S.ErrorText>
            {t('geofence.initialModal.contentNotAvailable')}{' '}
            <S.TextButton onClick={handleContactUs}>{t('geofence.initialModal.contactUs')}</S.TextButton>
          </S.ErrorText>
        )}
      </S.Content>
      <S.ButtonContainer>
        <S.Button onClick={onApply}>{t('common.next')}</S.Button>
      </S.ButtonContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  `,
  Text: styled.p`
    max-width: 240px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 28px;
  `,
  ErrorText: styled.p`
    margin-top: 15px;
    max-width: 300px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: ${({ theme }) => theme.colors.failRed};
  `,
  BoldText: styled.span`
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  `,
  TextButton: styled.a`
    cursor: pointer;
    text-decoration: underline;
  `,
  Button: styled(Button)`
    width: 100%;
    max-width: 226px;
    min-width: 226px;
    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.12);
  `,
  ButtonContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    align-self: center;
    margin-top: 30px;
    margin-bottom: 50px;
  `,
  BitesIcon: styled.div`
    margin-top: 35px;
  `,
  Header: styled.span`
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 15px;
  `,
  ReadyText: styled.span`
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 15px;
  `,
};

export default memo(InitialGeofenceModal);
