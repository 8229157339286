import BiteActionTypes from './bite.types';

import { IBite, IComment, ILoadFeedRequest, ISubtitles, ITranslatedVoiceover } from '../../types/bite';

export const biteQueryRequest = ({ orgId = null, isPublicFeed = false }: ILoadFeedRequest = {}) => ({
  type: BiteActionTypes.BITE_QUERY_REQUEST,
  payload: { orgId, isPublicFeed },
});

export const biteQuerySuccess = ({ bites, totalBites }) => ({
  type: BiteActionTypes.BITE_QUERY_SUCCESS,
  payload: { bites, totalBites },
});

export const biteQueryError = () => ({
  type: BiteActionTypes.BITE_QUERY_ERROR,
  payload: null,
});

export const biteLoadNext = ({ orgId = null, isPublicFeed = false }: ILoadFeedRequest = {}) => ({
  type: BiteActionTypes.BITE_LOAD_NEXT,
  payload: { orgId, isPublicFeed },
});

export const biteLoadNextSuccess = (bites: any[]) => ({
  type: BiteActionTypes.BITE_LOAD_NEXT_SUCCESS,
  payload: bites,
});

export const fetchSelectedBiteRequest = (payload: { biteId: number; processId: string; isPlaylist?: boolean }) => ({
  type: BiteActionTypes.FETCH_SELECTED_BITE_REQUEST,
  payload,
});

export const fetchSelectedBiteSuccess = (bite: IBite) => ({
  type: BiteActionTypes.FETCH_SELECTED_BITE_SUCCESS,
  payload: bite,
});

export const resetSelectedBite = () => ({
  type: BiteActionTypes.RESET_SELECTED_BITE,
});

export const addCommentRequest = (data: { text: string; file: any; type: string }, id: string | number) => ({
  type: BiteActionTypes.ADD_COMMENT_REQUEST,
  payload: {
    data,
    id,
  },
});

export const addCommentSuccess = (comment: IComment, type: string) => ({
  type: BiteActionTypes.ADD_COMMENT_SUCCESS,
  payload: { comment, type },
});

export const deleteCommentRequest = (data: { type: string; id: string | number }) => ({
  type: BiteActionTypes.DELETE_COMMENT_REQUEST,
  payload: {
    data,
  },
});

export const deleteCommentSuccess = (commentId: number, type: string) => ({
  type: BiteActionTypes.DELETE_COMMENT_SUCCESS,
  payload: { commentId, type },
});

export const clearSelectedBite = () => ({
  type: BiteActionTypes.CLEAR_SELECTED_BITE,
});

export const enableVideoPlaying = () => ({
  type: BiteActionTypes.ENABLE_VIDEO_PLAYING,
});

export const disableVideoPlaying = () => ({
  type: BiteActionTypes.DISABLE_VIDEO_PLAYING,
});

export const addMultipleChoicesAnswerSuccessBite = (data: any) => ({
  type: BiteActionTypes.MULTIPLE_CHOICES_QUATION_SUCCESS_BITE,
  payload: data,
});

export const setCommentSuggestion = (comment: string) => ({
  type: BiteActionTypes.SET_COMMENT_SUGGESTION,
  payload: comment,
});

export const setCommentSuggestionIsLoading = (isLoading: boolean) => ({
  type: BiteActionTypes.SET_COMMENT_SUGGESTION_IS_LOADING,
  payload: isLoading,
});

export const defineSubtitlesLocale = () => ({
  type: BiteActionTypes.DEFINE_SUBTITLES_LOCALE,
});

export const setSubtitlesDisabled = (payload: boolean) => ({
  type: BiteActionTypes.SET_SUBTITLES_DISABLED,
  payload,
});
export const translateSubtitles = (locale: string) => ({
  type: BiteActionTypes.TRANSLATE_SUBTITLES,
  payload: locale,
});
export const translateSubtitlesRequest = (payload: string) => ({
  type: BiteActionTypes.TRANSLATE_SUBTITLES_REQUEST,
  payload,
});
export const translateSubtitlesSuccess = (payload: ISubtitles) => ({
  type: BiteActionTypes.TRANSLATE_SUBTITLES_SUCCESS,
  payload,
});
export const translateSubtitlesError = (error: any) => ({
  type: BiteActionTypes.TRANSLATE_SUBTITLES_ERROR,
  payload: error,
});

export const translateVoiceovers = (payload: { locale: string }) => ({
  type: BiteActionTypes.TRANSLATE_VOICEOVERS,
  payload,
});
export const translateVoiceoversRequest = (payload: string) => ({
  type: BiteActionTypes.TRANSLATE_VOICEOVERS_REQUEST,
  payload,
});
export const setTranslateVoiceoversPendingData = (payload: {
  locale: string;
  pendingAudio: ITranslatedVoiceover | null;
}) => ({
  type: BiteActionTypes.SET_TRANSLATE_VOICEOVERS_PENDING_DATA,
  payload,
});
export const setTranslateVoiceoversData = (payload: { locale: string; audio: ITranslatedVoiceover | null }) => ({
  type: BiteActionTypes.SET_TRANSLATE_VOICEOVERS_DATA,
  payload,
});
export const translateVoiceoversError = (payload: { locale: string; error: any }) => ({
  type: BiteActionTypes.TRANSLATE_VOICEOVERS_ERROR,
  payload,
});

export const setTranslatedVoiceoversLocale = (payload: string | null) => ({
  type: BiteActionTypes.SET_TRANSLATED_VOICEOVERS_LOCALE,
  payload,
});
export const setIsAlwaysTranslate = (payload: boolean) => ({
  type: BiteActionTypes.SET_IS_ALWAYS_TRANSLATE,
  payload,
});
