const AuthActionTypes = {
  SET_IS_INITIAL_AUTH_IN_PROGRESS: 'SET_IS_INITIAL_AUTH_IN_PROGRESS',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_WITH_PHONE_REQUEST: 'LOGIN_WITH_PHONE_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  SIGNUP_WITH_PHONE_REQUEST: 'SIGNUP_WITH_PHONE_REQUEST',
  SIGNUP_WITH_EMAIL_REQUEST: 'SIGNUP_WITH_EMAIL_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',

  LOGOUT: 'LOGOUT',
  SET_AUTH: 'SET_AUTH',
  REFRESH_USER: 'REFRESH_USER',
  SET_WRONG_VERIFICATION_CODE: 'SET_WRONG_VERIFICATION_CODE',
  SET_IS_PHONE_MISSING: 'SET_IS_PHONE_MISSING',
  SET_IS_SOCIAL_LOGIN: 'SET_IS_SOCIAL_LOGIN',
  SOCIAL_LOGIN_REQUEST: 'SOCIAL_LOGIN_REQUEST',
  SOCIAL_LOGIN_REQUEST_END: 'SOCIAL_LOGIN_REQUEST_END',
  SET_MICROSOFT_CONTEXT: 'SET_MICROSOFT_CONTEXT',
  SET_TOKEN_AND_GET_USER: 'SET_TOKEN_AND_GET_USER',

  PASSWORD_RECOVERY_REQUEST: 'PASSWORD_RECOVERY_REQUEST',
  PASSWORD_RECOVERY_SUCCESS: 'PASSWORD_RECOVERY_SUCCESS',
  PASSWORD_RECOVERY_ERROR: 'PASSWORD_RECOVERY_ERROR',

  SET_NEW_RECOVERY_PASSWORD_REQUEST: 'SET_NEW_RECOVERY_PASSWORD_REQUEST',
  SET_NEW_RECOVERY_PASSWORD_ERROR: 'SET_NEW_RECOVERY_PASSWORD_ERROR',

  SET_IS_RESTRICTED_CONTENT_FOR_ANOTHER_ORG_MODAL_SHOWN: 'SET_IS_RESTRICTED_CONTENT_FOR_ANOTHER_ORG_MODAL_SHOWN',
  SET_IS_RESTRICTED_CONTENT_MODAL_SHOWN: 'SET_IS_RESTRICTED_CONTENT_MODAL_SHOWN',
  SET_IS_NOTHING_TO_DISPLAY_MODAL_SHOWN: 'SET_IS_NOTHING_TO_DISPLAY_MODAL_SHOWN',
  SET_IS_RETRICTED_ALLOW_JOIN_MODAL_SHOWN: 'SET_IS_RETRICTED_ALLOW_JOIN_MODAL_SHOWN',
  SET_IS_NOT_LOADING: 'SET_IS_NOT_LOADING',

  RESET_AUTH_ERROR: 'RESET_AUTH_ERROR',
  RESET_AUTH_ERRORS: 'RESET_AUTH_ERRORS',
  SET_AUTH_ERROR_CODES: 'SET_AUTH_ERROR_CODES',
  CLEAR_USER_PROFILE_ERRORS_BY_TYPE: 'CLEAR_USER_PROFILE_ERRORS_BY_TYPE',
  FINALIZE_USER_PROFILE: 'FINALIZE_USER_PROFILE',
  REFRESH_USER_SUCCESS: 'REFRESH_USER_SUCCESS',
  REFRESH_USER_FAILURE: 'REFRESH_USER_FAILURE',
  SET_REFRESH_USER_ERRORS: 'SET_REFRESH_USER_ERRORS',
};

export interface IFinalizeProfileAction {
  processId: string;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  onSuccess?: () => void;
}

export interface IUserOrganizations {
  id: number;
  user_organization_id: number;
  is_teams_related: boolean;
  name: string;
  is_profile_complete: boolean;
  show_dashboard: boolean;
  is_owner: boolean;
  is_creator: boolean;
  is_opted_out: boolean;
  brand_icon: string;
  brand_share: string;
  brand_footer: string;
  is_default: boolean;
  default_sharing_mode: EShareMode;
  lead_name: string;
  content_admin?: boolean;
}

export interface IEditProfileData {
  email?: string;
  phone?: string;
  firstName: string;
  lastName: string;
  profile_image?: string;
  organizationId: number;
  processId?: string;
}

export interface IEditProfileDataDetails {
  email: string | null;
  phone: string | null;
  first_name: string;
  last_name: string;
  profile_image: string | null;
  organizationId: number;
}

export enum EShareMode {
  ANYONE = 'anyone',
  REQUIRE_LOGIN = 'require_login',
  TEAM_ONLY = 'team_only',
  TEAM_ONLY_ALLOW_JOIN = 'team_only_allow_join',
  NO_ONE = 'no_one',
}

export default AuthActionTypes;
