import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  errorCodesSelector,
  isAuthErrorSelector,
  isPhoneMissingSelector,
  isSsoErrorSelector,
  isWrongVerificationSelector,
} from '../../../store/auth/auth.selectors';

const useAuthError = () => {
  const { t } = useTranslation();

  const isSsoError = useSelector(isSsoErrorSelector);
  const isAuthError = useSelector(isAuthErrorSelector);
  const errorCodes = useSelector(errorCodesSelector);
  const isPhoneMissing = useSelector(isPhoneMissingSelector);
  const isWrongVerification = useSelector(isWrongVerificationSelector);

  if (isSsoError) {
    return t('common.somethingWentWrong');
  }

  if (isPhoneMissing) {
    return t('authForm.phoneNumberNotExists');
  }

  if (isWrongVerification) {
    return t('authentication.verification.errorMsg');
  }

  if (errorCodes) {
    if (errorCodes.includes('1')) {
      return t('authForm.errorUserTaken');
    }

    if (errorCodes.includes('2')) {
      return t('authForm.errorInvalidNumber');
    }

    if (errorCodes.includes('3')) {
      return t('authForm.emailAlreadyTaken');
    }

    if (errorCodes.includes('4')) {
      return t('authForm.forgotPasswordForm.resetPassTooCommon');
    }

    // to implement on BE
    if (errorCodes.includes('auth.wrong_credentials')) {
      return t('authForm.wrongEmailOrPassword');
    }
  }

  // else case
  if (isAuthError) {
    return t('common.somethingWentWrong');
  }

  return null;
};
export default useAuthError;
