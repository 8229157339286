import React from 'react';
import styled from 'styled-components';
import { APP_WIDTH } from '../../utils/constants';
import { isMobile } from '../../utils/utils';

interface IProps {
  backgroundColor?: string;
  onBackdropClick?: () => void;
  backdropColor?: string;
}

const BottomSheet: React.FC<IProps> = ({
  backgroundColor = 'rgba(255, 255, 255, 0.9)',
  onBackdropClick,
  backdropColor,
  children,
}) => {
  const isDevice = isMobile();

  return (
    <>
      {(backdropColor || onBackdropClick) && <S.Backdrop backdropColor={backdropColor} onClick={onBackdropClick} />}
      <S.Container isDevice={isDevice} backgroundColor={backgroundColor}>
        {children}
      </S.Container>
    </>
  );
};

const S = {
  Container: styled.div<{ isDevice: boolean; backgroundColor: string }>`
    position: fixed;
    left: auto;
    right: auto;
    bottom: 0;
    width: ${({ isDevice }) => (isDevice ? '100%' : `${APP_WIDTH}px`)};
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    z-index: 5;
  `,
  Backdrop: styled.div<{ backdropColor: string }>`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ backdropColor }) => backdropColor || 'transparent'};
    z-index: 4;
  `,
};

export default BottomSheet;
