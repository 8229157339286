import React, { FC } from 'react';
import styled from 'styled-components';

import BiteItem from './BiteItem';

import { getIsRtl } from '../../locale/i18n';

interface IProps {
  bites: any[];
  onBiteClick?: (biteId: number, subject: string) => void;
  isLinear?: boolean;
}

const BiteList: FC<IProps> = ({ bites, onBiteClick, isLinear }) => {
  return (
    <S.List>
      {bites.map((bite, idx) => {
        let isLocked = false;
        if (
          isLinear &&
          idx > 0 &&
          bites[idx - 1]?.bite_share_user?.bite_progress !== 'done' &&
          !bite?.bite_share_user?.bite_progress
        ) {
          isLocked = true;
        }
        return <BiteItem bite={bite} onClick={onBiteClick} key={bite.id} isLocked={isLocked} />;
      })}
    </S.List>
  );
};

const S: any = {};

S.List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 23px;
  padding-bottom: 20px;
  ${() => (getIsRtl() ? 'direction: rtl;' : '')};
  writing-mode: horizontal-tb;
`;

export default BiteList;
