const Types = {
  BITE_QUERY_REQUEST: 'BITE_QUERY_REQUEST',
  BITE_QUERY_SUCCESS: 'BITE_QUERY_SUCCESS',
  BITE_QUERY_ERROR: 'BITE_QUERY_ERROR',
  BITE_LOAD_NEXT: 'BITE_LOAD_NEXT',
  BITE_LOAD_NEXT_SUCCESS: 'BITE_LOAD_NEXT_SUCCESS',

  FETCH_SELECTED_BITE_REQUEST: 'FETCH_SELECTED_BITE_REQUEST',
  FETCH_SELECTED_BITE_SUCCESS: 'FETCH_SELECTED_BITE_SUCCESS',
  RESET_SELECTED_BITE: 'RESET_SELECTED_BITE',

  ADD_COMMENT_REQUEST: 'ADD_COMMENT_REQUEST',
  ADD_COMMENT_SUCCESS: 'ADD_COMMENT_SUCCESS',

  DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
  DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',

  CLEAR_SELECTED_BITE: 'CLEAR_SELECTED_BITE',

  ENABLE_VIDEO_PLAYING: 'ENABLE_VIDEO_PLAYING',
  DISABLE_VIDEO_PLAYING: 'DISABLE_VIDEO_PLAYING',

  //multiple choices
  MULTIPLE_CHOICES_QUATION_REQUEST_BITE: 'MULTIPLE_CHOICES_QUATION_REQUEST_BITE',
  MULTIPLE_CHOICES_QUATION_SUCCESS_BITE: 'MULTIPLE_CHOICES_QUATION_SUCCESS_BITE',
  MULTIPLE_CHOICES_QUATION_ERROR_BITE: 'MULTIPLE_CHOICES_QUATION_ERROR_BITE',
  SET_COMMENT_SUGGESTION: 'SET_COMMENT_SUGGESTION',
  SET_COMMENT_SUGGESTION_IS_LOADING: 'SET_COMMENT_SUGGESTION_IS_LOADING',

  DEFINE_SUBTITLES_LOCALE: 'DEFINE_SUBTITLES_LOCALE_REQUEST',

  SET_SUBTITLES_DISABLED: 'SET_SUBTITLES_DISABLED',

  TRANSLATE_SUBTITLES: 'TRANSLATE_SUBTITLES',
  TRANSLATE_SUBTITLES_REQUEST: 'SET_TRANSLATE_SUBTITLES_REQUEST',
  TRANSLATE_SUBTITLES_SUCCESS: 'TRANSLATE_SUBTITLES_SUCCESS',
  TRANSLATE_SUBTITLES_ERROR: 'TRANSLATE_SUBTITLES_ERROR',

  TRANSLATE_VOICEOVERS: 'TRANSLATE_VOICEOVERS',
  TRANSLATE_VOICEOVERS_REQUEST: 'TRANSLATE_VOICEOVERS_REQUEST',
  SET_TRANSLATE_VOICEOVERS_PENDING_DATA: 'SET_TRANSLATE_VOICEOVERS_PENDING_DATA',
  SET_TRANSLATE_VOICEOVERS_DATA: 'SET_TRANSLATE_VOICEOVERS_DATA',
  TRANSLATE_VOICEOVERS_ERROR: 'TRANSLATE_VOICEOVERS_ERROR',

  SET_TRANSLATED_VOICEOVERS_LOCALE: 'SET_TRANSLATED_VOICEOVERS_LOCALE',
  SET_IS_ALWAYS_TRANSLATE: 'SET_ALWAYS_TRANSLATE_TO_LOCALE',
};

export default Types;
