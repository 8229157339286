import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseSVG } from '../../../../assets/icons/close.svg';
import { ReactComponent as CheckboxCheckedSVG } from '../../../../assets/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxUncheckedSVG } from '../../../../assets/icons/checkbox-unchecked.svg';
import { ReactComponent as ThreeDotsVerticalSVG } from '../../../../assets/icons/three-dots-vertical.svg';
import { ReactComponent as LoaderSVG } from '../../../../assets/loaders/loader-2.svg';
import LangsOverlay from './LangsOverlay';
import useTranslationOptions, { useLocaleLabel } from '../../../../hooks/useTranslationOptions';
import useBrowserLang from '../../../../hooks/useBrowserLang';
import useAlwaysTranslate from '../../../../hooks/useAlwaysTranslate';
import Popover from './Popover';
import { useTranslation } from 'react-i18next';
import localStorage from '../../../../utils/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { log } from '../../../../store/tracking/tracking.slice';
import { createPortal } from 'react-dom';
import { APP_WIDTH } from '../../../../utils/constants';
import { bodyPortal } from '../../../../App';
import { getIsRtl } from '../../../../locale/i18n';
import { ReactComponent as VoiceoverSVG } from '../../../../assets/icons/voiceover.svg';
import defaultTheme from '../../../../style/themes/defaultTheme';
import { selectedBiteSelector, translatedVoiceoversLocaleSelector } from '../../../../store/bite/bite.selectors';
import { translateVoiceovers } from '../../../../store/bite/bite.actions';
import { organizationHasFeatureFlagSelector } from '../../../../store/profile/profile.selectors';
import { Features } from '../../../../utils/featureFlag/featureFlag.types';

interface IProps {
  withLocalesTabs?: boolean;
  withAlwaysTranslate?: boolean;
  withDisplayAnimation: boolean;
  currentLocale: string | null;
  enabledLocale: string | null;
  isVisible: boolean;
  isLoading: boolean;
  isError: boolean;
  translatingIntoLocale: string | null;
  onSelect: (locale: string) => void;
}

const SubtitlesTranslationsControls = ({
  withLocalesTabs = true,
  withAlwaysTranslate = true,
  withDisplayAnimation,
  currentLocale,
  enabledLocale,
  isVisible: isVisibleProp,
  isLoading,
  isError,
  translatingIntoLocale,
  onSelect,
}: IProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isRtl = getIsRtl();

  const translatedVoiceoversLocale = useSelector(translatedVoiceoversLocaleSelector);

  const { selectedBite } = useSelector(selectedBiteSelector);
  const voiceoverTranslationsFeatureFlag = useSelector(
    organizationHasFeatureFlagSelector({
      orgId: parseInt(selectedBite?.orgid, 10),
      feature: Features.VOICEOVER_TRANSLATIONS,
    }),
  );

  const [isVisible, setIsVisible] = useState(false);
  const [isErrorDismissed, setIsErrorDismissed] = useState(false);

  const { isAlwaysTranslate, toggleAlwaysTranslate, enableAlwaysTranslate, disableAlwaysTranslate } =
    useAlwaysTranslate();

  const browserLang = useBrowserLang();
  const [selectedLocale, setSelectedLocale] = useState(enabledLocale === currentLocale ? browserLang : currentLocale);

  const [isShowLangsOverlay, setIsShowLangsOverlay] = useState(false);

  const targetLangRef = useRef(null);

  const { isLoading: isOptionsLoading, localesWithVoiceover } = useTranslationOptions({
    defaultLocale: enabledLocale,
    selectedLocale: currentLocale,
  });

  const enabledLocaleLabel = useLocaleLabel(enabledLocale) || t('components.translations.original');
  const selectedLocaleLabel = useLocaleLabel(selectedLocale);
  const currentLocaleLabel = useLocaleLabel(currentLocale);

  const displayIsSubtitlesLoading = isLoading && selectedLocale === translatingIntoLocale;
  const displayIsSubtitlesError = isError && selectedLocale === translatingIntoLocale && !isErrorDismissed;

  const withToggleAlwaysTranslate =
    !displayIsSubtitlesError && (displayIsSubtitlesLoading || currentLocale === selectedLocale) && withLocalesTabs;

  const withToggleTranslateVoiceovers =
    !displayIsSubtitlesError &&
    (displayIsSubtitlesLoading || currentLocale === selectedLocale) &&
    voiceoverTranslationsFeatureFlag &&
    localesWithVoiceover.has(selectedLocale);

  const heightLevels = [1, withToggleAlwaysTranslate ? 1 : 0, withToggleTranslateVoiceovers ? 1 : 0].reduce(
    (acc, x) => {
      return acc + x;
    },
    0,
  );

  const logContext = useMemo(
    () => ({
      enabledLocaleLabel,
      selectedLocaleLabel,
      isVisible,
      isVisibleProp,
      isShowLangsOverlay,
      browserLang,
      currentLocale,
      enabledLocale,
      selectedLocale,
      isLoading,
      isError,
      translatingIntoLocale,
      displayIsLoading: displayIsSubtitlesLoading,
      displayIsError: displayIsSubtitlesError,
      withToggleAlwaysTranslate,
    }),
    [
      browserLang,
      currentLocale,
      displayIsSubtitlesError,
      displayIsSubtitlesLoading,
      enabledLocale,
      enabledLocaleLabel,
      isError,
      isLoading,
      isShowLangsOverlay,
      isVisible,
      isVisibleProp,
      selectedLocale,
      selectedLocaleLabel,
      translatingIntoLocale,
      withToggleAlwaysTranslate,
    ],
  );

  const handleClose = useCallback(() => {
    dispatch(
      log({
        event: 'SubtitlesTranslationsControls: handleClose',
        data: logContext,
      }),
    );

    if (!isAlwaysTranslate) {
      localStorage.setItem('closedTranslationControlsTs', Date.now());
    }

    setIsVisible(false);
  }, [dispatch, isAlwaysTranslate, logContext]);

  const handleUseDefaultLang = useCallback(() => {
    dispatch(
      log({
        event: 'SubtitlesTranslationsControls: handleUseDefaultLang',
        data: logContext,
      }),
    );

    onSelect(enabledLocale);
    disableAlwaysTranslate();
  }, [disableAlwaysTranslate, dispatch, enabledLocale, logContext, onSelect]);

  const handleSelect = useCallback(
    (newSelectedLocale: string) => {
      if (newSelectedLocale !== enabledLocale) {
        if (isAlwaysTranslate) {
          if (newSelectedLocale === enabledLocale) {
            disableAlwaysTranslate();
          } else {
            enableAlwaysTranslate(newSelectedLocale);
          }
        }
      }

      onSelect(newSelectedLocale);
    },
    [disableAlwaysTranslate, enableAlwaysTranslate, enabledLocale, isAlwaysTranslate, onSelect],
  );

  const handleSelectFromList = useCallback(
    (newSelectedLocale: string) => {
      dispatch(
        log({
          event: 'SubtitlesTranslationsControls: handleSelectFromList',
          data: { ...logContext, newSelectedLocale },
        }),
      );

      handleSelect(newSelectedLocale);
    },
    [dispatch, handleSelect, logContext],
  );

  const handleTranslate = useCallback(async () => {
    dispatch(
      log({
        event: 'SubtitlesTranslationsControls: handleTranslate',
        data: logContext,
      }),
    );

    handleSelect(selectedLocale);
  }, [dispatch, handleSelect, logContext, selectedLocale]);

  const handleToggleAlwaysTranslate = useCallback(async () => {
    dispatch(
      log({
        event: 'SubtitlesTranslationsControls: handleToggleAlwaysTranslate',
        data: logContext,
      }),
    );

    toggleAlwaysTranslate(selectedLocale);
  }, [dispatch, logContext, selectedLocale, toggleAlwaysTranslate]);

  const handleToggleTranslatedVoiceoversLocale = useCallback(() => {
    dispatch(
      log({
        event: 'SubtitlesTranslationsControls: handleToggleTranslatedVoiceoversLocale',
        data: logContext,
      }),
    );

    if (translatedVoiceoversLocale) {
      dispatch(translateVoiceovers({ locale: null }));
      return;
    }

    dispatch(translateVoiceovers({ locale: translatingIntoLocale || selectedLocale }));
  }, [dispatch, logContext, selectedLocale, translatedVoiceoversLocale, translatingIntoLocale]);

  const handleShowLangsOverlay = useCallback(() => {
    setIsShowLangsOverlay(true);
  }, []);

  const handleHideLangsOverlay = useCallback(() => {
    setIsShowLangsOverlay(false);
  }, []);

  useEffect(() => {
    // currentLocale can be changed from the outside,
    // so we need to update selectedLocale via use effect, not via callback
    if (currentLocale !== selectedLocale && currentLocale !== enabledLocale) {
      setSelectedLocale(currentLocale);
    }
  }, [currentLocale, enabledLocale, selectedLocale]);

  useEffect(() => {
    const run = async () => {
      if (!isOptionsLoading && enabledLocale) {
        dispatch(
          log({
            event: 'SubtitlesTranslationsControls: show',
          }),
        );

        await new Promise((resolve) => setTimeout(resolve, withDisplayAnimation ? 500 : 0));

        setIsVisible(true);
      }
    };

    run();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptionsLoading, enabledLocale]);

  useEffect(() => {
    if (!isLoading) {
      setIsErrorDismissed(false);
    }
  }, [isLoading]);

  useEffect(() => {
    const dismissError = () => {
      setIsErrorDismissed(true);
    };

    document.addEventListener('click', dismissError);

    return () => {
      document.removeEventListener('click', dismissError);
    };
  }, []);

  return (
    <>
      {createPortal(
        <S.Container
          withDisplayAnimation={withDisplayAnimation}
          isVisible={isVisible && isVisibleProp}
          heightLevels={heightLevels}
        >
          <S.Top isRtl={isRtl}>
            <S.Title>
              {t('components.translations.subtitles')}
              {!withLocalesTabs ? ` ${currentLocaleLabel}` : ''}
            </S.Title>
            {withLocalesTabs && (
              <>
                <S.Tab
                  isSelected={currentLocale === enabledLocale && !displayIsSubtitlesLoading}
                  onClick={handleUseDefaultLang}
                >
                  {enabledLocaleLabel}
                </S.Tab>
                <S.Tab
                  isSelected={currentLocale === selectedLocale || displayIsSubtitlesLoading}
                  onClick={handleTranslate}
                  ref={targetLangRef}
                >
                  {selectedLocaleLabel}
                  {displayIsSubtitlesLoading && (
                    <S.LoaderContainer>
                      <LoaderSVG width={20} height={20} />
                    </S.LoaderContainer>
                  )}
                </S.Tab>
              </>
            )}
            <S.TakeSpace />
            <S.BtnIconContainer onClick={handleShowLangsOverlay}>
              <ThreeDotsVerticalSVG width={14} height={14} />
            </S.BtnIconContainer>
            <S.BtnIconContainer onClick={handleClose}>
              <CloseSVG width={14} height={14} />
            </S.BtnIconContainer>
          </S.Top>
          {withAlwaysTranslate && (
            <S.Bottom isRtl={isRtl} onClick={handleToggleAlwaysTranslate}>
              <S.BtnIconContainer>
                {isAlwaysTranslate ? (
                  <CheckboxCheckedSVG width={18} height={18} />
                ) : (
                  <CheckboxUncheckedSVG width={18} height={18} />
                )}
              </S.BtnIconContainer>
              <S.AlwaysTranslateLabel isRtl={isRtl}>
                {t('components.translations.alwaysUse')} {selectedLocaleLabel}
              </S.AlwaysTranslateLabel>
            </S.Bottom>
          )}
          {withToggleTranslateVoiceovers && (
            <S.Bottom isRtl={isRtl} onClick={handleToggleTranslatedVoiceoversLocale}>
              <S.BtnIconContainer>
                {translatedVoiceoversLocale ? (
                  <CheckboxCheckedSVG width={18} height={18} />
                ) : (
                  <CheckboxUncheckedSVG width={18} height={18} />
                )}
              </S.BtnIconContainer>
              <S.AlwaysTranslateLabel isRtl={isRtl}>
                <S.IconContainer marginRight>
                  <VoiceoverSVG width={14} height={14} stroke={defaultTheme.colors.black} />
                </S.IconContainer>
                {t('components.translations.translateVoiceovers')}
              </S.AlwaysTranslateLabel>
            </S.Bottom>
          )}
        </S.Container>,
        bodyPortal.current,
      )}
      <LangsOverlay
        isVisible={isShowLangsOverlay}
        isAlwaysTranslate={isAlwaysTranslate}
        selectedLocale={currentLocale}
        defaultLocale={enabledLocale}
        onSelect={handleSelectFromList}
        onClose={handleHideLangsOverlay}
        onToggleAlwaysTranslate={withToggleAlwaysTranslate ? handleToggleAlwaysTranslate : undefined}
      />
      <Popover targetRef={targetLangRef} isVisible={displayIsSubtitlesError} showDelay={250} onClick={handleTranslate}>
        <S.Error>{t('components.translations.translationFailed')}</S.Error>
      </Popover>
    </>
  );
};
export default memo(SubtitlesTranslationsControls);

const S = {
  Container: styled.div<{ withDisplayAnimation: boolean; isVisible: boolean; heightLevels: number }>`
    position: fixed;
    left: 50%;
    bottom: ${({ isVisible }) => (isVisible ? '8px' : '-200px')};
    width: 100%;
    max-width: ${APP_WIDTH - 16}px;
    height: ${({ heightLevels }) => heightLevels * 44}px;
    font-size: 14px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px ${({ theme }) => theme.colors.transparentBlack2};
    background-color: ${({ theme }) => theme.colors.white};
    transform: translateX(-50%);
    transition: all ${({ withDisplayAnimation }) => (withDisplayAnimation ? 300 : 0)}ms ease-in-out;
    overflow: hidden;
  `,
  Top: styled.div<{ isRtl: boolean }>`
    display: flex;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    justify-content: space-between;
    align-items: stretch;
    padding: 0 8px;
    height: 44px;
  `,
  Title: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    cursor: default;
  `,
  Tab: styled.div<{ isSelected: boolean }>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 16px 0px;
    color: ${({ isSelected, theme }) => (isSelected ? theme.colors.primaryBlue : theme.colors.text)};
    font-weight: ${({ isSelected }) => (isSelected ? 500 : 'normal')};
    border-bottom: 2px solid ${({ isSelected, theme }) => (isSelected ? theme.colors.primaryBlue : 'transparent')};
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGray1};
    }
  `,
  LoaderContainer: styled.div`
    position: absolute;
    right: -22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    width: 44px;
    pointer-events: none;
  `,
  TakeSpace: styled.div`
    flex: 1;
  `,
  IconContainer: styled.div<{ marginLeft?: boolean; marginRight?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ marginLeft }) => (marginLeft ? 'margin-left: 8px;' : '')}
    ${({ marginRight }) => (marginRight ? 'margin-right: 8px;' : '')}
  `,
  BtnIconContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGray1};
    }
  `,
  Bottom: styled.div<{ isRtl: boolean }>`
    display: flex;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
    padding: 0 8px;
    height: 44px;
    background-color: rgba(241, 243, 245, 0.7);
    cursor: pointer;
  `,
  AlwaysTranslateLabel: styled.div<{ isRtl: boolean }>`
    display: flex;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
  `,
  Error: styled.span`
    font-size: 14px;
    font-weight: 500;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  `,
};
