import CommentItem from './CommentItem';
import React, { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { EFlattenedCommentType, TCommentItemRefType, TFlattenedComments } from '../types';

type TCommentsRowRenderProps = {
  index: number;
  style: React.CSSProperties;
  data: {
    comments: TFlattenedComments;
    createReplyOnComment: () => void;
    setSize: (i: number, number: number) => void;
  };
};

const MARGIN_BOTTOM = 15;

const CommentsRowRender: React.FC<TCommentsRowRenderProps> = ({ style, index, data }) => {
  const itemRef = React.useRef<TCommentItemRefType>(null);
  const { comments, createReplyOnComment, setSize } = data;
  const item = comments[index];

  const setHeight = useCallback(() => {
    if (!itemRef.current) {
      return;
    }

    const roundedUpHeight = Math.ceil(itemRef.current.getBoundingClientRect().height);
    setSize(index, roundedUpHeight + MARGIN_BOTTOM);
  }, [setSize, index]);

  useEffect(() => {
    setHeight();
  }, [setSize, index, item.content, setHeight]);

  return (
    <div style={style}>
      {item.type === EFlattenedCommentType.Header ? (
        <S.TimeAgo ref={itemRef} isFirst={index === 0}>
          {item.content}
        </S.TimeAgo>
      ) : (
        <CommentItem
          itemRef={itemRef}
          comment={item.content}
          createReplyOnComment={createReplyOnComment}
          onContentUpdate={setHeight}
        />
      )}
    </div>
  );
};

const S = {
  TimeAgo: styled.h4<{ isFirst: boolean }>`
    font-size: 16px;
    line-height: 24px;
    opacity: 0.8;

    margin-bottom: 10px;

    ${({ theme }) => css`
      color: ${theme.colors.darkBlueBg};
    `}

    ${({ isFirst }) =>
      !isFirst &&
      css`
        margin-top: 10px;
      `}
  `,
};

export default CommentsRowRender;
